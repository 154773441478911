

// import React from 'react';
// import {  Flex, Spin } from 'antd';
// const contentStyle = {
//   padding: 50,
//   borderRadius: 4,
// };
// const content = <div style={contentStyle} />;
// const PageLoading = () => (
//   <Flex gap="middle" vertical >
//     <Flex gap="middle">
//       <Spin tip="Loading" size="large" fullscreen="true" >
//         {content}
//       </Spin>
//     </Flex>
//   </Flex>
// );
// export default PageLoading;

import React from 'react';
import '../../../src/index.css'
import { Alert, Flex, Spin } from 'antd';

const contentStyle = {
  padding: 50,
  borderRadius: 4,
};

const content = <div style={contentStyle} />;

const PageLoading = () => (
  <div style={{ background: "linear-gradient(90deg, #8DC63F 10%, #007DC1 100%)", minHeight: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
    <Flex gap="middle" vertical>
      <Flex gap="middle">
        <Spin tip="Loading..." size="large" >
          {content}
        </Spin>
      </Flex>
    </Flex>
  </div>
);

export default PageLoading;
