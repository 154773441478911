// src/reducers.js

import { combineReducers } from 'redux';
// import userReducer from './reducers/userReducer';

import userReducer from './reducer';
const rootReducer = combineReducers({
  user: userReducer,
  // Add other reducers here if needed
});

export default rootReducer;